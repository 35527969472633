import React from 'react'
import Faq from '../Faq/Faq'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import socks from '../../assests/socks.png';
import socks1 from '../../assests/socks1.png';
import ethereum from '../../assests/ethereum1.png';
import shipping from '../../assests/shipping.png';
import quemark from '../../assests/questionmark.png';
import './Main.css';
import { motion } from 'framer-motion';

const Main = () => {
  return (
    <main>
   <div className='section-one pt-5 pt-sm-0'>
    <Container>
        <Row className='p-3'>
        
        <Col md={7} className='mt-5 mt-sm-5'>
                <motion.div className='left-part'
                    initial={{x: -100}}
                    animate={{x:0}}
                    transition={
                        {ease: "linear", duration: 1 }
                    }
                >
                    <div className="sync d-flex">
                        <div className='zksync-icon'>
                            <img src={socks1} alt='socks1'></img>
                        </div>
                        <div className='zksync-desc'>
                            <h5>Rare limited Edition Socks</h5>
                            <a type="button" className="btn btn-primary mint-btn" target="_blank" rel="noopener noreferrer" href="https://omnisea.org/SyncSocks">Mint Socks</a>
                        </div>
                    </div>
                    <div className="sync d-flex">
                        <div className='zksync-icon'>
                            <img src={ethereum} alt='socks1'></img>
                        </div>
                        <div className='zksync-desc'>
                            <h5>Support Zero knowledge</h5>
                            <p>By Minting a pair of Socks, you are supporting the adoption of zkSync</p>
                        </div>
                    </div>
                    <div className="sync d-flex">
                        <div className='zksync-icon'>
                            <img src={shipping} alt='socks1'></img>
                        </div>
                        <div className='zksync-desc'>
                        <h5>Free Shipping</h5>
                            <p>Redeem an nft for a real pair of Socks shipped directly to your doorstep.</p>
                        </div>
                    </div>
                    <div>
                    <svg width="494" height="486" viewBox="0 0 494 486" fill="none" class="story-line_story-line--center__kW06K" preserveAspectRatio="none">
                        <path class="icon-shape" d="M1 2C0.0529194 83.5963 46.9728 246.789 250.716 246.789C454.46 246.789 493 402 491 485" stroke="#AC66F2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" vector-effect="non-scaling-stroke"></path></svg>
                   <div className='d-flex justify-content-center justify-content-md-end'>
                   <div className='eth_logo'>
                   <img src={quemark} alt='quemark'></img>
                        </div>
                   </div>
                    
                    </div>
                </motion.div>
            </Col>
            <Col xs={{order: 'first'}} sm={{order: 'last'}} md={5} className='mt-3 mt-sm-5'>
                <motion.div className='right-part'
                initial={{x: 200}}
                animate={{x:0}}
                transition={
                    {ease: "linear", duration: 1 }
                }
                >
                    <img src={socks} alt='socks image' ></img>
                </motion.div>
            </Col>
       
        
            
           
        </Row>
        </Container>
   </div>
     <Container>
        <Faq/>
    </Container>
    </main>
  
   
  
  )
}

export default Main