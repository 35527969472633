import { Fragment } from "react";
import { Accordion } from "react-bootstrap";
import './Faq.css';
import { motion, AnimatePresence } from "framer-motion";

const Faq = () => {
  return (
    <Fragment>
            <div className="faq-section my-3 my-sm-5">
                <h2 className="title text-center mb-3">Frequently Asked Questions</h2>
                <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0" className="mb-4">
                        <Accordion.Header> What is zkSync?</Accordion.Header>
                        <Accordion.Body>
                            <p>The zkSync blockchain is a layer-2 scaling solution for the Ethereum network. Basically, it makes the Ethereum network better by offering faster and cheaper transactions without sacrificing the security or decentralization of the Ethereum network.</p>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1" className="mb-4">
                        <Accordion.Header>What is SyncSocks?</Accordion.Header>
                        <Accordion.Body>
                            <p>SyncSocks represents a novel approach to merging the digital and physical worlds by utilizing the Zero Knowledge technology. SyncSocks are 10000 non-fungible tokens in the form of digital collectibles, with each token being backed by a physical pair of Socks that can be redeemed. Upon redemption, the NFT is permanently removed from circulation.</p>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2" className="mb-4">
                        <Accordion.Header>Can a redeemed Socks be converted back to an NFT?</Accordion.Header>
                        <Accordion.Body>
                            <p>No, the redemption cannot be reversed once it has been made.</p>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3" className="mb-4">
                        <Accordion.Header>Any future benefit?</Accordion.Header>
                        <Accordion.Body>
                            <p>Additional physical and redeemable utilities will be tied to the NFTs, including airdrops and DAO participation.</p>
                        </Accordion.Body>
                    </Accordion.Item>
                    
                </Accordion>
            </div>
        </Fragment>
  )
}

export default Faq