import React from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo from  '../../assests/logo.png';
import discord from '../../assests/Discord.png';
import twitter from '../../assests/Twitter.png';
import './Header.css';

const Header = () => {
  return (
   <>
    <Navbar>
        <Container>
          <Navbar.Brand href="#">
          <img src={logo} alt="logo" height="30"></img>
          </Navbar.Brand>
          <Nav className="ms-auto">
            
            <Nav.Link href="https://discord.com/invite/SyncSocks"  rel="noopener noreferrer" target="_blank">
            <img src={discord} alt="discord" height="30"></img>
            </Nav.Link>
            <Nav.Link href="https://twitter.com/Syncsocks" rel="noopener noreferrer" target="_blank">
            <img src={twitter} alt="twitter" height="30"></img>
            </Nav.Link>
          </Nav>
        </Container>
      </Navbar>
   
   </>
  )
}

export default Header