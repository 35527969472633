import React from 'react'
import './Footer.css'

const Footer = () => {
  return (
    <footer class="footer mt-auto pt-3">
    <div class="container text-center">
        <div class="footer-section">
            <p class="mb-0">Designed for the zkSync community
            </p>
        </div>
    </div>
</footer>
  )
}

export default Footer